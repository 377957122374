import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";

export default {
  install(Vue) {
    Vue.prototype.$global = {
      requiredRule: [(v) => !!v || i18n.t("required")],
      notRequiredRule: [],
      requiredNumberRule: [
        (v) =>
          Number(v.replace(/[^0-9.-]+/g, "")) > 0 ||
          i18n.t("please enter valid number"),
      ],
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.t("Please enter email address"),
      ],
      requiredNumberAcceptZeroRule: [
        (v) => v.toString().length >= 1 || i18n.t("please enter valid number"),
      ],
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],
      percentageRule: [
        (v) =>
          (v.toString().length >= 1 && v <= 100) ||
          i18n.t("percentage not valid"),
      ],

      /////////  Array /////////
      FilterPermissions(Screen_Code) {
        if (store.state.permissions.length > 0) {
          var find = store.state.permissions.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.some(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(
        ARRAY,
        SearchIn,
        filterBy,
        ReturnElement = null
      ) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter.length ? filter.at(0)[ReturnElement] : null;
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      SelectFromArray() {
        let original_array = arguments.length ? arguments[0] : [];
        let required_object = arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = required_object.reduce(
            (prevKey, nextKey) =>
              object[arguments[nextKey]]
                ? {
                  ...prevKey,
                  [arguments[nextKey]]: object[arguments[nextKey]],
                }
                : { ...prevKey },
            {}
          );
          filtered_array.push(new_object);
        });
        return filtered_array;
      },
      SelectAsFromArray(original_array = [], required_object = Object) {
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = Object;
          Object.keys(required_object).forEach((key) => {
            if (object[required_object[key]])
              new_object[key] = object[required_object[key]];
          });
          filtered_array.push({ ...new_object });
        });
        return filtered_array || [];
      },
      SelectFromObject() {
        let original_object = arguments.length ? arguments[0] : Object;
        let required_keys = arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_object = required_keys.reduce(
          (prevKey, nextKey) =>
            original_object[arguments[nextKey]]
              ? {
                ...prevKey,
                [arguments[nextKey]]: original_object[arguments[nextKey]],
              }
              : { ...prevKey },
          {}
        );
        return filtered_object;
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      PaginateArray(ARRAY, PAGE_SIZE, PAGE_NUMBER) {
        return ARRAY.slice(
          (PAGE_NUMBER - 1) * PAGE_SIZE,
          PAGE_NUMBER * PAGE_SIZE
        );
      },
      // Dates
      GetCurrentTime() {
        var time = new Date();
        return time.getHours() + ":" + time.getMinutes();
      },
      GetCurrentDate() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      },

      GetFirstDayOfMonth() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
        return firstDay.toISOString().split("T")[0];
      },
      SubtractDates(FIRST_DATE, SECOND_DATE) {
        // const dateOne = new Date(FIRST_DATE);
        // const dateTwo = new Date(SECOND_DATE);
        // return dateTwo.getDate() - dateOne.getDate();
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
      },
      SubtractFromDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() - Number(VALUE));
        return date.toISOString().split("T")[0];
      },
      AddToDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() + Number(VALUE));
        return date.toISOString().split("T")[0];
      },
      AddMonthsToDate(DATE, VALUE = 1) {
        const date = new Date(DATE);
        var calculated = new Date(date.setMonth(date.getMonth() + VALUE));
        return calculated.toISOString().split("T")[0];
      },
      GetDaysBetween(FIRST_DATE, SECOND_DATE) {
        console.log(FIRST_DATE, SECOND_DATE);
        var getDaysArray = function (start, end) {
          for (
            var arr = [], dt = new Date(start);
            dt <= new Date(end);
            dt.setDate(dt.getDate() + 1)
          ) {
            arr.push(new Date(dt));
          }
          return arr;
        };
        var dayList = getDaysArray(new Date(FIRST_DATE), new Date(SECOND_DATE));
        var dates = dayList.map((v) => v.toISOString().slice(0, 10)).join(",");
        return dates.split(",").map((i) => i);
      },
      GetMonthsBetween(FIRST_DATE, SECOND_DATE) {
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var diff = (dateSecond.getTime() - dateFirst.getTime()) / 1000;
        diff /= 60 * 60 * 24 * 7 * 4;
        return Math.abs(Math.round(diff));
      },
      GetTimesSlots(START_TIME = '00:00:00', END_TIME = '23:55:00', INTERVAL_MINUTES = 5) {
        let times = [];
        let startTime = START_TIME
        let endTime = END_TIME
        let start = new Date();
        let [startHours, startMinutes] = startTime?.time?.split(':')?.map(Number);
        start.setHours(startHours, startMinutes, 0, 0);

        let end = new Date();
        let [endHours, endMinutes] = endTime?.time?.split(':')?.map(Number);
        end.setHours(endHours, endMinutes, 0, 0);

        while (start <= end) {
          let hours = start.getHours().toString().padStart(2, '0');
          let minutes = start.getMinutes().toString().padStart(2, '0');
          let seconds = '00';
          times.push(`${hours}:${minutes}:${seconds}`);
          start.setMinutes(start.getMinutes() + INTERVAL_MINUTES);
        }
        return times;
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },
      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "SAR",
        });

        return formatter.format(NUMBER);
      },

      GET_TOTAL_PRICE_WITHOUT_VAT(ITEMS) {
        const total_price = ITEMS.reduce((last_result, active_item) => {
          if (active_item.price_include_vat == 1) {
            const total_with_vat = +active_item.price * +active_item.quantity;
            const total_vat =
              active_item.total_price_without_vat *
              (active_item.tax_percent / 100);
            return +last_result + (total_with_vat - total_vat);
          } else {
            const total_without_vat = +active_item.price * +active_item.quantity;
            return +last_result + total_without_vat;
          }
        }, 0);
        return total_price;
      },

      GET_DISCOUNT(DISCOUNT, TOTAL_PRICE) {
        let discount_amount = 0;
        let percent_value = 0;
        if (DISCOUNT.type == 1) {
          discount_amount = TOTAL_PRICE - DISCOUNT.value;
          percent_value = (DISCOUNT.value / TOTAL_PRICE) * 100;
        }
        if (DISCOUNT.type == 2) {
          discount_amount = TOTAL_PRICE - TOTAL_PRICE * (DISCOUNT.value / 100);
          percent_value = DISCOUNT.value;
        }
        if (DISCOUNT.type == 3) {
          if (DISCOUNT.coupon.discount_type == 1) {
            discount_amount = TOTAL_PRICE - DISCOUNT.coupon.discount;
            percent_value = (DISCOUNT.coupon.discount / TOTAL_PRICE) * 100;
          }
          if (DISCOUNT.coupon.discount_type == 2) {
            discount_amount = TOTAL_PRICE - TOTAL_PRICE * (DISCOUNT.coupon.discount / 100);
            percent_value = DISCOUNT.coupon.discount;
          }
        }
        const discountCalc = {
          type: DISCOUNT.type,
          value: DISCOUNT.value,
          discount_amount: discount_amount,
          percent_value: percent_value,
          total_price: TOTAL_PRICE,
          coupon: DISCOUNT.coupon,
        };
        return discountCalc;
      },

      GET_CALCULATED_ITEMS(ITEMS, CALCULATED_DISCOUNT) {
        ITEMS.forEach((item) => {
          // console.log("price_include_vat", item.price_include_vat);
          if (item.price_include_vat) {
            item.price_without_vat =
              item.price -
              (item.price - item.price / (item.tax_percent / 100 + 1));
            item.total_with_vat = item.price * item.quantity;
            item.total_price_without_vat =
              item.price_without_vat * item.quantity;
            item.total_vat = item.total_with_vat - item.total_price_without_vat;
          } else {
            item.price_without_vat = item.price;
            item.total_price_without_vat = item.price * item.quantity;
            item.total_vat =
              item.total_price_without_vat * (item.tax_percent / 100);
            item.total_with_vat = item.total_price_without_vat + item.total_vat;
          }
          item.total_discount = 0
          item.applied_in_coupon = 0
          if (CALCULATED_DISCOUNT.type == 3) {
            if (CALCULATED_DISCOUNT.coupon.items.includes(item.item_id)) {
              item.applied_in_coupon = 1
              if(CALCULATED_DISCOUNT.coupon.discount_type == 1){
                let value_in_price =  CALCULATED_DISCOUNT.coupon.discount >= item.price_without_vat ? item.price_without_vat : CALCULATED_DISCOUNT.coupon.discount
                item.total_discount =  value_in_price * item.quantity;
              }else{
                let value_in_price = item.price_without_vat *  CALCULATED_DISCOUNT.coupon.discount / 100
                item.total_discount = value_in_price * item.quantity
              }
            }
          } else {
            item.total_discount = CALCULATED_DISCOUNT.discount_amount
              ? item.total_price_without_vat *
              (CALCULATED_DISCOUNT.percent_value / 100)
              : 0;
          }
          item.total_price_with_discount =
            item.total_price_without_vat - item.total_discount;
          item.total_vat_after_discount =
            item.total_price_with_discount * (item.tax_percent / 100);
          item.total_price_after_discount_with_vat =
            item.total_price_with_discount + item.total_vat_after_discount;
        });
        return ITEMS;
      },
      ARRAY_REDUCE(ITEMS, KEY) {
        return ITEMS.reduce((last_result, active_item) => {
          return +last_result + +active_item[KEY];
        }, 0);
      },
      PRICE_CALCULATION(ITEMS, DISCOUNT_OBJECT) {
        /* 
          1- get total items price  (Check each item if price include vat or not and return total ) (Important for next step)
          2- get discount value 
          3- update each item 
          4- calculate totals (All items) 
          5- return 
      */
        // GET Total Price :
        const total_price_without_vat = this.GET_TOTAL_PRICE_WITHOUT_VAT(ITEMS);
        // GET Discount :  DISCOUNT_OBJECT KEYS => {type : 1 OR 2 , value : amount OR percent value} :
        const calculated_discount = this.GET_DISCOUNT(
          DISCOUNT_OBJECT,
          total_price_without_vat
        );
        // GET Calculated items :
        const calculated_items = this.GET_CALCULATED_ITEMS(
          ITEMS,
          calculated_discount
        );
        // Totals Calculated items  :
        const price_without_vat = this.ARRAY_REDUCE(
          calculated_items,
          "price_without_vat"
        );
        const total_discount = this.ARRAY_REDUCE(
          calculated_items,
          "total_discount"
        );
        const total_price_with_discount = this.ARRAY_REDUCE(
          calculated_items,
          "total_price_with_discount"
        );
        const total_vat_after_discount = this.ARRAY_REDUCE(
          calculated_items,
          "total_vat_after_discount"
        );
        const total_price_after_discount_with_vat = this.ARRAY_REDUCE(
          calculated_items,
          "total_price_after_discount_with_vat"
        );
        const total_vat = this.ARRAY_REDUCE(calculated_items, "total_vat");
        const total_quantity = this.ARRAY_REDUCE(calculated_items, "quantity");
        // Return
        return {
          item_title: i18n.t("total"),
          price_without_vat: price_without_vat,
          total_price_without_vat: total_price_without_vat,
          discount: calculated_discount,
          total_price_with_discount: total_price_with_discount, // without vat
          quantity: total_quantity,
          total_discount: total_discount,
          total_vat: total_vat,
          total_vat_after_discount: total_vat_after_discount,
          total_price_after_discount_with_vat:
            total_price_after_discount_with_vat,
          new_array: calculated_items,
        };
      },

      FILE_TYPE(FILE) {
        // Base64
        console.log(FILE);
        if (String(FILE).substring(0, 5) == "data:") {
          console.log(
            "FILE TYPE =>",
            FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          );
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            default:
              // return FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
              return "notFound";
          }
        }
        // Normal
        else {
          // return FILE ? FILE.split('.').pop() : '';
          switch (FILE.type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            default:
              // return FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
              return "notFound";
          }
        }
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt = Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );

        return textEncrypt;

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();
      },
      DECRYPT(KEY, ENCODED) {
        // 1
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(
          ENCODED
        ).toString(Vue.prototype.$CryptoJS.enc.Utf8);
        return textDecrypt;
        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(key);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var decrypted = Vue.prototype.$CryptoJS.AES.decrypt(encryptedString, x,{iv:iv});
        // return decrypted.toString();
      },
      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },
      sendNotification(TITLE = 'Qawaem - POS', CONTENT) {
        let OPTIONS = {
          body: i18n.t(CONTENT),
          data: '',
          icon: require('../assets/maskable_icon_x48.png'),
          dir: 'rtl',
          // image : require('../assets/maskable_icon_x512.png'),
          // requireInteraction: true,
          silent: false,
          vibrate: [200, 100, 200]
        }
        function checkPermission() {
          return Notification.requestPermission().then(status => status === 'granted');
        }
        function senNotification() {
          checkPermission().then(isAllowed => {
            if (!isAllowed) {
              return
            }
            new Notification(i18n.t(TITLE), OPTIONS)
          })
        }
        senNotification()
      },
    };
  },
};
